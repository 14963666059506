import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
    <div class="row">
      <div class="twelve columns">
        <header>
          <h1 style={{textAlign:`center`, marginTop:`3rem`, fontSize:'6rem;'}}>
            <Link
              to="/"
              style={{
                color: `#548f6f`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
        </header>
      </div>
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
